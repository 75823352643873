import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import cn from 'clsx';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';
// import DemoModal from 'components/DemoModal';

// import { Main } from 'styles/features';
import { signUp } from '../../utils/contentProvider/function';
import LoginWithGoogle from '../../components/LoginWithGoogle';
import TrustedBy from '../../components/TrustedBy';
import TestimonialsSlider from '../../components/TestimonialsSlider/TestimonialsSlider';
import 'styles/features.scss';
import NewFaq from '../../components/NewFaq/NewFaq';

const editWebsiteFaq = [
  {
    q: 'How to edit the website using ruttl?',
    a: (
      <>
        <ol>
          <li>
            <span className="bold">Project Creation:&nbsp;</span>
            Initiate a website feedback project on ruttl.
          </li>
          <li>
            <span className="bold">URL Insertion:&nbsp;</span>
            Add your website&apos;s URL.
          </li>
          <li>
            <span className="bold">Edit Activation:&nbsp;</span>
            Switch to Edit mode for real-time changes.
          </li>
          <li>
            <span className="bold">Content & Images:&nbsp;</span>
            Effortlessly swap text and images.
          </li>
          <li>
            <span className="bold">Layout & Design Enhancement:&nbsp;</span>
            Enhance layout by rearranging sections and refining spacing,
            margins, and fonts.
          </li>
          <li>
            <span className="bold">Developer Collaboration:&nbsp;</span>
            Collaborate, comment, and share with your designer for accurate
            implementation.
          </li>
        </ol>
      </>
    ),
  },
  {
    q: 'Can developers access website code after design changes are executed?',
    a: 'After you use the inspect feature to edit and make required changes to the website design, your developers can simply copy the entire altered code under the CSS tab in the Activities Panel. This can then be pasted directly into the code of live website files.',
  },
  {
    q: 'Which websites does ruttl work with?',
    a: 'ruttl works with static, WordPress, Vue.js, and React sites for collaborating on visual website feedback. In fact, you can use ruttl to work on pretty much every website. Just paste the website URL and get started!',
  },
  {
    q: 'I only want my clients to review, not edit the website design — is this possible?',
    a: 'Of course! You can choose to share the guest link with your clients, and then they won’t be able to make any edits on the website design. This way, you can use our edit mode internally only with the ones whom you have added via email address and given edit access.',
  },
  {
    q: 'I want to hide, undo, or even completely delete edits. Is that possible too?',
    a: ' Yes, with ruttl, you can carry out all the above functions on all edits.',
  },
  {
    q: 'How do we resolve edits on ruttl? Is that possible?',
    a: 'Yes absolutely. You can resolve edits that have been made on ruttl. To do so, go to edit mode and hover to the card of edit in the activities panel, which you’d like to resolve. Click on the menu, select ‘Resolve’ and your edit/set of edits shall be resolved. You can always unresolve this.',
  },
  {
    q: 'How do my developers or designers access the replaced image(s) on ruttl?',
    a: 'All your replaced images will be available inside the activities panel itself. Hover to the edit where the image is replaced and check the activities panel. Any member from your team can download this image and replace it in the live website file.',
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));

const EditMode = ({ location }) => {
  const [showVideoModal, setVideoModal] = useState(false);

  function toggleVideoModal() {
    setVideoModal(!showVideoModal);
  }

  function clickHandler(e) {
    if (e.target.id === 'demo-modal') setVideoModal(false);
  }

  useEffect(() => {
    window.addEventListener('click', clickHandler);

    return () => window.removeEventListener('click', clickHandler);
  }, [clickHandler]);

  useEffect(() => {
    document.body.style.overflow = showVideoModal ? 'hidden' : 'auto';
  }, [showVideoModal]);

  return (
    <Layout location={location}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Edit website 🔥 | ruttl features"
            description="Easily edit web elements like spacing, font, alignment, image replacement etc., with ruttl's edit mode feature to provide perfect changes to the developers."
            url="https://ruttl.com/features/edit-website/"
          />
          <div
            id="demo-modal"
            className={cn(['demo-modal', { show: showVideoModal }])}>
            <button
              type="button"
              aria-label="close"
              className="button-unstyled"
              onClick={toggleVideoModal}>
              <img src="/assets/img/close.svg" alt="close" title="close" />
            </button>
            {showVideoModal && (
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/oUvX7eW0eTM"
                frameBorder="0"
                title="ruttl demo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
          <main className="features-styled-main">
            <section className="features-hero">
              <div className="container">
                <h1>Edit website</h1>
                <p>
                  Getting precise changes done on web-projects would no longer
                  be daunting. Easily edit website elements like position of
                  elements, font properties, text alignment, image replacement
                  using Ruttl&apos;s edit mode.
                </p>

                <div
                  className="reveal-1 button-container"
                  style={{ marginBottom: 30 }}>
                  <LoginWithGoogle />

                  <div>
                    <a
                      className="button button-white"
                      href="https://web.ruttl.com/share/demo/"
                      style={{
                        height: 54,
                        paddingInline: 35,
                      }}
                      target="_blank"
                      rel="noreferrer noopener">
                      Try demo
                      <svg width="20" height="20" viewBox="0 0 512 512">
                        <path
                          fill="none"
                          stroke="#160647"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="48"
                          d="M268 112l144 144-144 144M392 256H100"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <TrustedBy />
            </section>
            <div className="container">
              <div className="feature-banner">
                <button
                  type="button"
                  aria-label="play"
                  className="button button-transparent"
                  onClick={toggleVideoModal}>
                  <img
                    src="/assets/img/edit-mode.png"
                    alt="youtube banner of ruttl edit mode video"
                    title="youtube banner of ruttl edit mode video"
                    className="banner-thumb"
                  />
                  <img
                    className="play-button"
                    src="/assets/img/play.svg"
                    alt="play icon designed by brucira"
                    title="play icon designed by brucira"
                  />
                </button>
              </div>
            </div>
            <div className="section features-main" id="features">
              <div className="container">
                <h2 className="center">Features</h2>
                <div className="feature-set-single" id="comment-live-website">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <video
                        src="/assets/img/edit-live-website.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(0px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '420px' }}>
                        Make edits on live websites
                      </h2>
                      <p>
                        Do all the changes in real time and see how they would
                        look without switching back end and front end.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="feature-set-single" id="edit-live-website">
                  <div className="flex flex-wrap align-items-center row-reverse-mob">
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(0px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '310px' }}>
                        Edit website content
                      </h2>
                      <p>
                        Edit the content on your website and see the changes in
                        real time with your team on ruttl.
                      </p>
                    </div>
                    <div className="feature-img">
                      <video
                        src="/assets/img/edit-text.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                  </div>
                </div>
                <div className="feature-set-single" id="edit-website-content">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <video
                        src="/assets/img/upload-image.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(0px)' }}>
                      <h2 className="feature-head">
                        Replace images on live website
                      </h2>
                      <p>
                        See how the images would appear on your final website.
                        Change the images by using our Unsplash integration, or
                        quickly upload an image from your computer.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="feature-set-single" id="client-commenting">
                  <div className="flex flex-wrap align-items-center row-reverse-mob">
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(0px)' }}>
                      <h2 className="feature-head">Inspect CSS</h2>
                      <p>
                        No need to switch between multiple softwares to edit
                        website content. Quickly view clean CSS and fix issues
                        with ruttl.
                      </p>
                    </div>
                    <div className="feature-img">
                      <img
                        src="/assets/img/show-activities.png"
                        alt="ruttl css inspecting feature"
                        title="ruttl css inspecting feature"
                      />
                    </div>
                  </div>
                </div>
                <div className="feature-set-single" id="inspect-css">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <video
                        src="/assets/img/responsive.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-10px)' }}>
                      <h2 className="feature-head">Review mobile web</h2>
                      <p>
                        Know how the mobile web version appears and make
                        iterations by inspecting CSS whenever you like.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-get-started">
              <GetStartedCenter toggleSignup={toggleSignup} />
            </div>
            <TestimonialsSlider />

            <section className="usecases-main" id="usecases">
              <div className="container">
                <h2 className="heading" style={{ marginBottom: '25px' }}>
                  ruttl solves a lot of usecases
                </h2>
                {/* <p>
                We built ruttl to be the one-stop solution for your web design
                needs. Here’s a look at the use cases ruttl is perfect for:
              </p> */}

                <div className="flex flex-wrap">
                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-designer">
                      <img
                        src="/assets/img/designer.png"
                        alt="ruttl ui ux designer avatar"
                        title="ruttl ui ux designer avatar"
                      />
                      <h3>
                        <span className="blue-text">UI/UX Designer</span>
                        &nbsp;— Get your designs coded pixel perfectly
                      </h3>
                      <p>
                        As a designer, it&apos;s really important that your
                        design and code match perfectly.&nbsp;
                        <Link to="/">ruttl</Link>
                        &nbsp;allows you to edit the staging link so you can
                        share the exact values with the developers to create
                        pixel perfect designs.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>

                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-developer">
                      <img
                        src="/assets/img/developer.png"
                        alt="ruttl developer avatar"
                        title="ruttl developer avatar"
                      />
                      <h3>
                        <span className="blue-text">Developers</span>
                        &nbsp;— Reduce coordination time by collecting exact CSS
                        values
                      </h3>
                      <p>
                        As a developer, you get exact values of the changes
                        needed to be done on the webpage. These changes can be
                        found under the Activity Tab. You can just copy and
                        paste the same on your main source
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-content-writer">
                      <img
                        src="/assets/img/content-writer.png"
                        alt="ruttl content writer avatar"
                        title="ruttl content writer avatar"
                      />
                      <h3>
                        <span className="blue-text">Content Writers</span>
                        &nbsp; — Visualize how the content looks on the website
                      </h3>
                      <p>
                        As a content writer, you no longer have to use word
                        documents in order to send website copy. Write the
                        content directly on the live website and visualize how
                        it looks with the design. Word limits are no longer a
                        problem!
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div
                      className="usecase-single"
                      id="usecase-project-manager">
                      <img
                        src="/assets/img/project-manager.png"
                        alt="ruttl project manager avatar"
                        title="ruttl project manager avatar"
                      />
                      <h3>
                        <span className="blue-text">Project managers</span>
                        &nbsp;— Manage multiple web design projects easily with
                        a bird’s eye view of every single activity
                      </h3>
                      <p>
                        With ruttl, project managers can clearly see the
                        progress on a project. All comments and suggestions are
                        in a single spot to guide changes, resolve pain points,
                        and ensure that the project is moving forward at the
                        right pace.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-agency">
                      <img
                        src="/assets/img/agencies.png"
                        alt="ruttl agencies avatar"
                        title="ruttl agencies avatar"
                      />
                      <h3>
                        <span className="blue-text">Agencies</span>
                        &nbsp;— Work efficiently on multiple project and never
                        miss a deadline
                      </h3>
                      <p>
                        Agencies can work on multiple web projects seamlessly
                        and invite clients to review and provide feedback. This
                        saves a lot of time and money by reducing the
                        coordination hours between developers and designers.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-marketing-team">
                      <img
                        src="/assets/img/marketing-team.png"
                        alt="ruttl avatar for marketing teams"
                        title="ruttl avatar for marketing teams"
                      />

                      <h3>
                        <span className="blue-text">Marketing teams</span>
                        &nbsp;— Reduce dependency on designers and developers;
                        edit websites without coding knowledge
                      </h3>
                      <p>
                        Try on different banners on your website or change the
                        content as you need without depending on designers and
                        developers.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  {/*  <div className="usecase-block">
                  <div className="usecase-single" id="usecase-sales-team">
                    <img
                      src="/assets/img/sales-team.png"
                      alt="ruttl avatar for sales teams"
                    />
                    <h3>
                      <span className="blue-text">Sales teams</span>
                      &nbsp;— Create custom sales experiences to increase sales and
                      offer a personal touch to your customers
                    </h3>
                    <p>
                      Quickly change text, images, styling and bring your visions to
                      life. ruttl enables the sales team to customise demos before
                      each meeting, which increases the bottom line conversion rate
                    </p>
                    <a className="button" href="https://web.ruttl.com/signup">
                      Start Free Trial
                    </a>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single">
                    <img src="/assets/img/freelancer.png" alt="Freelancer" />
                    <h3>
                      <span className="blue-text">Freelancers</span>
                      &nbsp;— Independent designers, developers, and content
                      creators can use ruttl to perfect their web projects
                    </h3>
                    <p>
                      ruttl has been designed keeping in mind both teams and
                      individual users. Freelance designers, developers, and writers
                      can use ruttl to review, edit, and build websites, web apps,
                      and more.
                    </p>
                    <a className="button" href="https://web.ruttl.com/signup" className="button">
                      Start Free Trial
                    </a>
                  </div>
                </div> */}
                </div>
                <p style={{ fontSize: '18px', fontWeight: '600' }}>
                  To know about the competitive advanatges of ruttl, check out
                  our
                  <Link to="/alternatives/">&nbsp;alternatives&nbsp;</Link>
                  page.
                </p>
              </div>
            </section>
            <NewFaq data={editWebsiteFaq} />
          </main>
        </>
      )}
    </Layout>
  );
};

EditMode.propTypes = {
  location: PropTypes.object,
};

export default EditMode;
